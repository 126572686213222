<template>
  <b-card no-body class="card-apply-job">
    <b-card-header class="">
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar size="42" :src="img" />
        </b-media-aside>
        <b-media-body class="my-auto">
          <h4 class="my-auto">{{ name }}</h4></b-media-body
        >
      </b-media>
    </b-card-header>
    <b-card-body>
      <b-media no-body class="">
        <b-media-aside class="mr-1">
          <b-media-aside class="mr-1">
            <b-avatar rounded variant="light-primary" size="34">
              <feather-icon icon="CalendarIcon" size="18" />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="date">
            <h6 class="mb-0">
              {{ formattedDate }}
            </h6>
            <small> {{ formattedStartTime }} to {{ formattedEndTime }}</small>
          </b-media-body>
        </b-media-aside></b-media
      >
      <div class="d-flex mt-2 justify-content-between">
        <!-- <b-col
          lg="5"
        > -->
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary">
          Confirm
        </b-button>
        <!-- </b-col> -->
        <!-- <b-col
          lg="7"
        > -->
        <b-button
          id="show-btn"
          v-b-modal.modal-reschedule
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="showRescheduleModal()"
        >
          Reschedule
        </b-button>

        <!-- </b-col> -->
      </div>
    </b-card-body>
    <!-- modal vertical center -->
    <!-- <b-modal
      ref="reschedule-modal"
      hide-footer
      ok-title="Accept"
      modal-class="modal-primary"
      centered
      title="Reschedule"
    >

      <div class="mx-auto">
        <b-col
          lg="8"
          md="10"
          sm="10"
        >
          <div class="px-2">
            <h4>Reschedule interview time for <span class="text-primary">{{ name }}</span></h4>
            <b-form-group
              label="Select Date"
              class="mb-1"
            >
              <b-form-datepicker
                v-model="updateDate"
                selected-variant="primary"
                default-value=""
                :state="updateDate.length > 0 ? true : false"
                @context="setFormattedDate"
              />
            </b-form-group>
            <b-form-group
              label="Select Start Time"
              class="mb-1"
            >
              <b-form-timepicker
                v-model="updateStartTime"
                locale="en"
                :state="updateStartTime.length > 0 ? true : false"
                @context="setFormattedStartTime"
              />
            </b-form-group>
            <b-form-group
              label="Select End Time"
              class="mb-1"
            >
              <b-form-timepicker
                v-model="updateEndTime"
                locale="en"
                :state="updateEndTime.length > 0 ? true : false"
                @context="setFormattedEndTime"
              />
            </b-form-group>

          </div>
        </b-col>
        <b-col
          lg="5"
          md="5"
          sm="5"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"

            class="mx-auto w-25 my-3"
            variant="primary"
            @click="handleReschedule()"
          >
            Update
          </b-button>
        </b-col>
      </div>
    </b-modal> -->
    <b-modal
      ref="reschedule-modal"
      hide-footer
      ok-title="Accept"
      modal-class="modal-primary"
      centered
      size="lg"
      title="Reschedule"
    >
      <b-row class="">
        <b-col lg="10" md="10" sm="10" class="mx-auto">
          <div class="px-lg-2">
            <h4>
              Reschedule interview time for
              <span class="text-primary">{{ name }}</span>
            </h4>
            <b-form-group label="Select Date" class="mb-1">
              <b-form-datepicker
                v-model="updateDate"
                selected-variant="primary"
                class="w-sm-75"
                default-value="555"
                :state="updateDate.length > 0 ? true : false"
                @context="setFormattedDate"
              />
            </b-form-group>
            <b-form-group label="Select Time" class="mb-1">
              <b-form-timepicker
                v-model="updateStartTime"
                class="w-sm-75"
                locale="en"
                :state="updateStartTime.length > 0 ? true : false"
                @context="setFormattedStartTime"
              />
            </b-form-group>
            <b-form-group label="Add Note" class="mb-1">
              <b-form-textarea
                v-model="addNote"
                placeholder="Write here..."
                rows="4"
              />
            </b-form-group>
            <!-- <b-form-group label="Select End Time" class="mb-1">
              <b-form-timepicker
                v-model="updateEndTime"
                class="w-sm-75"
                locale="en"
                :state="updateEndTime.length > 0 ? true : false"
                @context="setFormattedEndTime"
              />
            </b-form-group> -->
            <div class="d-flex justify-content-center">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mx-auto d-inline-block my-3"
                variant="primary"
                @click="handleReschedule()"
              >
                Update
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col lg="10" md="10" sm="10" class="mx-auto" />
      </b-row>
    </b-modal>
  </b-card>
</template>
<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BButtonGroup,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCol,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormTimepicker,
  BImg,
  BInputGroupAppend,
  BMedia,
  BMediaAside,
  BMediaBody,
  BRow,
} from "bootstrap-vue"
import flatPickr from "vue-flatpickr-component"

import Ripple from "vue-ripple-directive"

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BCardHeader,
    BMedia,
    BImg,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BFormDatepicker,
    BInputGroupAppend,
    BFormTextarea,
    BCardText,
    BFormInput,
    BCardBody,
    BButtonGroup,
    BFormGroup,
    BRow,
    BCol,
    BFormTimepicker,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    formattedDate: {
      type: String,
      required: true,
    },
    startTime: {
      type: String,
      required: true,
    },
    formattedStartTime: {
      type: String,
      required: true,
    },
    endTime: {
      type: String,
      required: true,
    },
    formattedEndTime: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      updateDate: "",
      updateStartTime: "",
      updateEndTime: "",
      updateFormattedDate: "",
      updateFormattedStartTime: "",
      updateFormattedEndTime: "",
    }
  },
  mounted() {
    this.updateDate = this.date
    this.updateStartTime = this.startTime
    this.updateEndTime = this.endTime
  },
  methods: {
    showRescheduleModal() {
      this.$refs["reschedule-modal"].show()
    },
    setFormattedDate(ctx) {
      this.updateFormattedDate = ctx.selectedFormatted
    },
    setFormattedStartTime(ctx) {
      this.updateFormattedStartTime = ctx.formatted
    },
    setFormattedEndTime(ctx) {
      this.updateFormattedEndTime = ctx.formatted
    },
    handleReschedule() {
      // $bvModal.hide(`${id}`)
      if (this.updateDate && this.updateStartTime && this.updateEndTime) {
        this.date = this.updateDate
        this.formattedDate = this.updateFormattedDate
        this.startTime = this.updateStartTime
        this.formattedStartTime = this.updateFormattedStartTime
        this.endTime = this.updateEndTime
        this.formattedEndTime = this.updateFormattedEndTime
        this.$refs["reschedule-modal"].hide()
      }
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.date {
  height: 50px;
}
</style>
